import request from '@/utils/request'

// 查询字典类型分页
export function pageType (query) {
  return request({
    url: '/system/dict/types/page',
    method: 'get',
    params: query
  })
}
// 查询字典类型列表
export function listType (query) {
  return request({
    url: '/system/dict/types/list',
    method: 'get',
    params: query
  })
}

// 查询字典类型详细
export function getType (dictId) {
  return request({
    url: '/system/dict/types/' + dictId,
    method: 'get'
  })
}

// 新增字典类型
export function addType (data) {
  return request({
    url: '/system/dict/types',
    method: 'post',
    data: data
  })
}

// 修改字典类型
export function updateType (data) {
  return request({
    url: '/system/dict/types',
    method: 'put',
    data: data
  })
}

// 删除字典类型
export function delType (dictId) {
  return request({
    url: '/system/dict/types/' + dictId,
    method: 'delete'
  })
}

// 刷新参数缓存
export function refreshCache () {
  return request({
    url: '/system/dict/types/refreshCache',
    method: 'delete'
  })
}

// 获取字典选择框列表
export function optionselect () {
  return request({
    url: '/system/dict/types/optionselect',
    method: 'get'
  })
}
